import { getResaleSiteFromUrl } from '@/utils/functionUtils'
import { ReactNode } from 'react'

// Componente feito para alternar entre duas integrações
// de um mesmo componente em função da nova API de pedidos
// e a necessidade de coexistência com a antiga.

export const IntegrationSwapper = ({
  isDealer = true,
  components: { ifIsMotiva, ifIsNotMotiva },
}: {
  isDealer?: boolean
  components: {
    ifIsMotiva: ReactNode
    ifIsNotMotiva: ReactNode
  }
}) => {
  //liberando broker novo para motiva
  //const isMotiva = getResaleSiteFromUrl() === 'motiva' || isDealer
  return isDealer ? ifIsMotiva : ifIsNotMotiva
}
